<template>
  <b-button-toolbar class="justify-content-md-center">
    <b-button-group
      class="mr-2 mb-1"
    >
      <b-button
        v-if="isActiveButtonBack"
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="outline-secondary"
        @click="$emit('back-button')"
      >
        Atras
      </b-button>
      <b-button
        v-if="isActiveButtonNext"
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="outline-secondary"
        @click="$emit('next-button')"
      >
        Siguiente
      </b-button>
      <b-button
        v-if="isActiveButtonSave"
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        @click="$emit('save-button')"
      >
        Guardar
      </b-button>
    </b-button-group>
  </b-button-toolbar>
</template>
<script>
import {
  BButton, BButtonToolbar, BButtonGroup,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default ({
  components: {
    BButton,
    BButtonToolbar,
    BButtonGroup,
  },
  directives: {
    Ripple,
  },
  props: {
    isActiveButtonBack: {
      type: Boolean,
      require: false,
      default: () => true,
    },
    isActiveButtonNext: {
      type: Boolean,
      require: false,
      default: () => true,
    },
    isActiveButtonSave: {
      type: Boolean,
      require: false,
      default: () => true,
    },
  },
})
</script>
