<template>
  <b-container>
    <b-row class="justify-content-md-center">
      <b-col
        cols="12"
        md="6"
      >
        <b-form @submit.prevent>
          <b-row>
            <b-col
              v-if="isActiveAvatar"
              cols="12"
            >
              <div
                class="text-center"
                @click="ActivateFormFile"
              >
                <b-avatar
                  :src="getFilePathTraducido"
                  text="Foto"
                  size="104px"
                />
                <br>
                Presiona para añadir una foto
                <hr>
              </div>
              <b-form-group
                label="Foto de perfil"
                label-for="users-avatar"
              >
                <validation-provider
                  #default="{ errors }"
                  rules="ext:jpg,jpeg,png"
                  name="Avatar"
                >
                  <b-form-file
                    id="fileInput"
                    ref="file-input"
                    v-model="avatar"
                    class="mb-2"
                    placeholder="Selecciona un archivo"
                    style="visibility: hidden;position: absolute;top: 0;left: -5000px;"
                    accept="image/jpeg, image/png, image/jpeg"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group
                label="Nombre"
                label-for="users-name"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Nombre"
                  vid="nombre"
                  rules="required|alphaSpaces"
                  immediate
                >
                  <b-form-input
                    id="users-name"
                    v-model="first_name"
                    placeholder="Nombre"
                    :state="errors.length > 0 ? false : null"
                    name="users-name"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group
                label="Apellidos"
                label-for="users-apellido"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Apellidos"
                  vid="apellidos"
                  rules="required|alphaSpaces"
                  immediate
                >
                  <b-form-input
                    id="users-apellido"
                    v-model="last_name"
                    placeholder="Apellidos"
                    :state="errors.length > 0 ? false : null"
                    name="users-apellido"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              sm="6"
            >
              <b-form-group
                label="Email"
                label-for="users-email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  vid="email"
                  rules="required|email"
                  immediate
                >
                  <b-form-input
                    id="users-email"
                    v-model="emailUsers"
                    placeholder="Email"
                    :state="errors.length > 0 ? false : null"
                    name="users-email"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              v-if="'id' in this.$route.params ? false : true"
              cols="12"
              sm="6"
            >
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">Contraseña</label>
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Contraseña"
                  vid="password"
                  rules="required"
                  immediate
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="password"
                      :state="errors.length > 0 ? false : null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      placeholder="Contraseña"
                      autocomplete="new-password"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              sm="6"
            >
              <b-form-group
                label="Rol"
                label-for="users-roles"
              >
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  name="Rol"
                  immediate
                >
                  <v-select
                    v-model="roles"
                    name="users-roles"
                    label="name"
                    :loading="isLoadingRoles"
                    :options="rowsRoles"
                    :clearable="false"
                    placeholder="Seleccione una opción"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              sm="6"
            >
              <b-form-group
                label="Tipo de cargo"
                label-for="users-tipo_cargo_id"
              >
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  name="Tipo de cargo"
                  immediate
                >
                  <v-select
                    v-model="tipo_cargo_id"
                    name="users-tipo_cargo_id"
                    label="name"
                    :loading="isLoadingCargo"
                    :options="rowsTipoCargo"
                    :clearable="false"
                    placeholder="Seleccione una opción"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              sm="6"
            >
              <b-form-group
                label="Estado"
                label-for="users-status_id"
              >
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  name="Estado"
                  immediate
                >
                  <v-select
                    v-model="status_id"
                    name="users-status_id"
                    label="name"
                    :loading="isLoadingStatus"
                    :options="rowsStatus"
                    :clearable="false"
                    placeholder="Seleccione una opción"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>

import { ValidationProvider } from 'vee-validate'
import {
  BRow, BCol, BFormGroup, BFormInput, BForm, BContainer, BAvatar, BFormFile, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    ValidationProvider,
    BAvatar,
    BFormFile,
    BContainer,
    BInputGroup,
    BInputGroupAppend,
    vSelect,
  },
  mixins: [togglePasswordVisibility],
  props: {
    isActiveAvatar: {
      type: Boolean,
      require: false,
      default: () => false,
    },
    profileData: {
      type: Object,
      require: false,
      default: () => {},
    },
  },
  data() {
    return {
      // Users
      emailUsers: null,
      tipo_cargo_id: [],
      roles: [],
      password: null,
      status_id: [],
      // profile
      avatar: null,
      first_name: null,
      last_name: null,
      getFilePathTraducido: `${process.env.VUE_APP_URL_API}/avatar/logo-default.png`,

      // select data
      rowsRoles: [],
      rowsStatus: [],
      rowsTipoCargo: [],
      isLoadingRoles: false,
      isLoadingStatus: false,
      isLoadingCargo: false,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  watch: {
    emailUsers(value) {
      const emailUsers = {
        key: 'emailUsers',
        info: value,
      }
      this.$emit('input-email', emailUsers)
    },
    tipo_cargo_id(value) {
      const tipoCargo = {
        key: 'tipo_cargo_id',
        info: value,
      }
      this.$emit('input-tipo-cargo-id', tipoCargo)
    },
    roles(value) {
      const roles = {
        key: 'roles',
        info: value,
      }
      this.$emit('input-roles', roles)
    },
    password(value) {
      const password = {
        key: 'password',
        info: value,
      }
      this.$emit('input-password', password)
    },
    avatar(value) {
      if (value != null) {
        this.getFilePathTraducido = URL.createObjectURL(value)
      } else {
        this.getFilePathTraducido = `${process.env.VUE_APP_URL_API}/avatar/logo-default.png`
      }
      const avatar = {
        key: 'avatar',
        info: value,
      }
      this.$emit('input-avatar', avatar)
    },
    first_name(value) {
      const firstName = {
        key: 'first_name',
        info: value,
      }
      this.$emit('input-first-name', firstName)
    },
    last_name(value) {
      const lastName = {
        key: 'last_name',
        info: value,
      }
      this.$emit('input-last-name', lastName)
    },
    status_id(value) {
      const statusId = {
        key: 'status_id',
        info: value,
      }
      this.$emit('input-status-id', statusId)
    },
    profileData() {
      if (this.profileData !== undefined && Object.keys(this.profileData).length > 0) {
        this.first_name = this.profileData.profile.first_name
        this.last_name = this.profileData.profile.last_name
        this.tipo_cargo_id = this.profileData.user_tipo_cargo
        this.emailUsers = this.profileData.email
        this.status_id = this.profileData.status_user
        this.getFilePathTraducido = this.profileData.avatar
        if (this.rowsRoles.length > 0) {
          this.roles = this.rowsRoles.filter(row => row.slug === this.profileData.role)
        }
      }
    },
  },
  mounted() {
    this.getRoles()
    this.getStatus()
    this.getTypeCargo()
  },
  methods: {
    ActivateFormFile() {
      document.getElementById('fileInput').click()
    },
    getRoles() {
      this.isLoadingRoles = true
      this.$http.get('/v1/roles/')
        .then(res => {
          this.isLoadingRoles = false
          this.rowsRoles = res.data
        })
    },
    getStatus() {
      this.isLoadingStatus = true
      this.$http.get('/v1/status/get/type')
        .then(res => {
          this.isLoadingStatus = false
          this.rowsStatus = res.data
        })
    },
    getTypeCargo() {
      this.isLoadingCargo = true
      this.$http.get('/v1/tipo-cargo/get/type')
        .then(res => {
          this.rowsTipoCargo = res.data
          this.isLoadingCargo = false
        })
    },
  },
}
</script>
