<template>
  <b-form
    v-if="isActiveContract"
    @submit.prevent
  >
    <b-row>
      <b-col
        cols="12"
        md="3"
      >
        <b-form-group
          label="Fecha de contrato"
          label-for="users-fecha_contrato"
        >
          <validation-provider
            #default="{ errors }"
            name="Fecha de contrato"
            vid="Fecha de contrato"
            rules="required"
            immediate
          >
            <datepicker
              v-model="fecha_contrato"
              placeholder="Fecha de expedición"
              :use-utc="true"
              :bootstrap-styling="true"
              :language="espanish"
              format="d MMMM yyyy"
              :state="errors.length > 0 ? false : null"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        sm="3"
      >
        <b-form-group
          label="Tipo de contrato"
          label-for="users-rowsTipoContrato"
        >
          <validation-provider
            #default="{ errors }"
            rules="required"
            name="Tipo de contrato"
            immediate
          >
            <v-select
              v-model="tipo_contrato_id"
              name="users-rowsTipoContrato"
              label="name"
              :options="rowsTipoContrato"
              :clearable="false"
              placeholder="Seleccione una opción"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="3"
      >
        <b-form-group
          label="Número de contrato"
          label-for="users-n_contrato"
        >
          <validation-provider
            #default="{ errors }"
            name="Número de contrato"
            vid="n_contrato"
            rules="required"
            immediate
          >
            <b-form-input
              id="users-n_contrato"
              v-model="n_contrato"
              name="users-n_contrato"
              placeholder="Número de contrato"
              :state="errors.length > 0 ? false:null"
              :disabled="!$can('update', 'contrato')"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        sm="3"
      >
        <b-form-group
          label="Asesor Academico"
          label-for="users-user_id_vendedor"
        >
          <validation-provider
            #default="{ errors }"
            rules="required"
            name="Asesor Academico"
            immediate
          >
            <v-select
              v-model="user_id_vendedor"
              name="users-user_id_vendedor"
              label="name"
              :options="rowsUserByType"
              :disabled="!$can('update', 'contrato')"
              :clearable="false"
              placeholder="Seleccione una opción"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row align-h="center">
      <b-col
        cols="12"
        sm="4"
      >
        <b-form-group
          label="Precio del contrato"
          label-for="users-total_price"
        >
          <validation-provider
            #default="{ errors }"
            rules="required"
            name="Precio del contrato"
            immediate
          >
            <cleave
              id="number"
              v-model="total_price"
              class="form-control"
              :raw="true"
              :options="optionsNumber.number"
              placeholder="10,000"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col cols="12">
        <hr>
      </b-col>
    </b-row>
    <vue-event-calendar
      v-if="total_price"
      title="Fechas de pago"
      :events="fechasPago"
    >
      <template
        slot-scope="props"
        color="#000"
      >
        <b-row align-h="center">
          <b-col cols="6">
            <b-button-toolbar>
              <b-button-group
                size="sm"
              >
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="relief-info"
                  @click="agregarDayPay"
                >
                  Agregar
                </b-button>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="relief-info"
                  @click="clearDate"
                >
                  Limpiar filtrado
                </b-button>
              </b-button-group>
            </b-button-toolbar>
          </b-col>
          <b-col cols="6">
            <div class="ml-1">
              <p> Cuotas: <strong>{{ fechasPago.length }}</strong></p>
              <p>Valor de coutas: <strong>{{ totalCuotPrice }}</strong></p>
              <p> Valor del contrato: <strong>{{ formatNumber(total_price) }}</strong></p>
              <p>Faltante: <strong>{{ formatNumber(total_price - totalCuotPriceTotal) }}</strong></p>
            </div>
          </b-col>
        </b-row>
        <div
          v-for="(event, index) in props.showEvents"
          :key="index"
          class="event-item"
        >
          <p>Número: <strong>{{ event.id }}</strong></p>
          <p>Fecha de pago: <strong>{{ setDate(event.date) }}</strong></p>
          <p>Valor a pagar: <strong>{{ event.value }}</strong></p>
          <b-button-toolbar
            class="mb-1"
          >
            <b-button-group
              size="sm"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="relief-warning"
                @click="editDayPay(event)"
              >
                Editar
              </b-button>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="relief-danger"
                @click="deleteDayPay(event)"
              >
                Eliminar
              </b-button>
            </b-button-group>
          </b-button-toolbar>
        </div>
      </template>
    </vue-event-calendar>
    <b-row>
      <b-col cols="12">
        <hr>
      </b-col>
      <b-col
        cols="12"
        md="12"
      >
        <b-form-group
          label="Observaciones"
          label-for="users-observaciones"
        >
          <validation-provider
            #default="{ errors }"
            name="observaciones"
            vid="observaciones"
            rules=""
            immediate
          >
            <b-form-textarea
              id="textarea-default"
              v-model="observaciones"
              placeholder="Se usa para colocar cualquier observación al contrato"
              rows="3"
              :state="errors.length > 0 ? false:null"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
    </b-row>
    <b-modal
      v-model="modalShow"
      cancel-variant="outline-secondary"
      ok-title="Enviar"
      no-close-on-esc
      no-close-on-backdrop
      centered
      size="md"
    >
      <template #modal-header>
        <h5>Fechas de pago</h5>
      </template>
      <validation-observer
        ref="FechaPagoForm"
      >
        <b-form @submit.prevent>
          <b-row>
            <b-col
              cols="12"
              sm="12"
            >
              <b-form-group
                label="¿Cuantas cuotas desea agregar?"
                label-for="users-cuotas_pago"
              >
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  name="¿Cuantas cuotas desea agregar?"
                  immediate
                >
                  <v-select
                    v-model="numberCuotes"
                    name="users-cuotas-agregar"
                    label="name"
                    :options="rowsCuotasDePago"
                    placeholder="Seleccione una opción"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="12"
            >
              <b-form-group
                label="Fecha de pago"
                label-for="users-fecha-pago"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Fecha de contrato"
                  vid="Fecha de contrato"
                  rules="required"
                  immediate
                >
                  <datepicker
                    v-model="datePay.fecha_pago"
                    placeholder="Fecha de expedición"
                    :use-utc="true"
                    :bootstrap-styling="true"
                    :language="espanish"
                    format="D, d MMMM yyyy"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              sm="12"
            >
              <b-form-group
                label="Valor de la cuota"
                label-for="users-cuota-pay"
              >
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  name="Valor de la cuota"
                  immediate
                >
                  <cleave
                    id="number"
                    v-model="datePay.value"
                    class="form-control"
                    :raw="true"
                    :options="optionsNumber.number"
                    placeholder="10,000"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
      <template #modal-footer>
        <div class="w-100">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="float-right ml-1"
            @click="saveDatePay()"
          >
            Enviar
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-secondary"
            class="float-right"
            @click="closeDayPay()"
          >
            Cerrar
          </b-button>
        </div>
      </template>
    </b-modal>
  </b-form>
</template>
<script>
import {
  BRow, BCol, BFormGroup, BFormInput, BButton, BFormTextarea, BButtonToolbar, BButtonGroup, BForm,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import Vue from 'vue'
import vSelect from 'vue-select'
import Cleave from 'vue-cleave-component'
import Ripple from 'vue-ripple-directive'
import moment from 'moment'
import vueEventCalendar from 'vue-event-calendar'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import 'vue-event-calendar/dist/style.css'
import Datepicker from 'vuejs-datepicker'
import { es } from 'vuejs-datepicker/dist/locale'

Vue.use(vueEventCalendar, {
  locale: 'es',
})

export default {
  name: 'Contrat',
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BButtonToolbar,
    BButtonGroup,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    Cleave,
    BFormTextarea,
    Datepicker,
  },
  directives: {
    Ripple,
  },
  props: {
    isActiveContract: {
      type: Boolean,
      require: false,
      default: () => true,
    },
    setEditInfo: {
      type: Object,
      require: false,
      default: () => {},
    },
  },
  data() {
    return {
      modalShow: false,
      numberCuotes: {
        id: 1,
        name: 1,
      },
      datePay: {
        id: null,
        fecha_pago: null,
        value: null,
      },
      totalPay: null,
      fechasPago: [],
      optionsNumber: {
        number: {
          numeral: true,
          numeralDecimalMark: ',',
          delimiter: '.',
        },
      },
      isCreateEdit: null,

      // Contrato
      n_contrato: null,
      fecha_contrato: null,
      total_price: null,
      cuotas_pago: null,
      observaciones: null,
      contrato_municipio_id: null,
      tipo_contrato_id: null,
      user_id_vendedor: [],

      // Select Data
      rowsTipoContrato: [],
      rowsUserByType: [],
      rowsPais: [],
      rowsDepartamento: [],
      rowsCiudad: [],
      rowsCuotasDePago: [],
      espanish: es,
    }
  },
  computed: {
    is_cuotas_pago() {
      if (this.total_price) {
        return false
      }
      return true
    },
    totalCuotPrice() {
      const price = this.fechasPago.reduce((acc, el) => {//eslint-disable-line
        return acc + parseFloat(el.price)
      }, 0)
      return new Intl.NumberFormat('de-DE', { currency: 'COP' }).format(price)
    },
    totalCuotPriceTotal() {
      const price = this.fechasPago.reduce((acc, el) => {//eslint-disable-line
        return acc + parseFloat(el.price)
      }, 0)
      return price
    },
  },
  watch: {
    n_contrato(val) {
      const nContrato = {
        key: 'n_contrato',
        info: val,
      }
      this.$emit('input-n-contrato', nContrato)
    },
    fecha_contrato(val) {
      const fechaContrato = {
        key: 'fecha_contrato',
        info: this.customFormatter(val),
      }
      this.$emit('input-fecha-contrato', fechaContrato)
    },
    total_price(val) {
      const totalPrice = {
        key: 'total_price',
        info: val,
      }
      this.$emit('input-total-price', totalPrice)
    },
    cuotas_pago(val) {
      const cuotasPago = {
        key: 'cuotas_pago',
        info: val,
      }
      this.$emit('input-cuotas-pago', cuotasPago)
    },
    observaciones(val) {
      const observaciones = {
        key: 'observaciones',
        info: val,
      }
      this.$emit('input-observaciones', observaciones)
    },
    tipo_contrato_id(val) {
      const tipoContratoId = {
        key: 'tipo_contrato_id',
        info: val,
      }
      this.$emit('input-tipo-contrato-id', tipoContratoId)
      if (val) {
        if (!('id' in this.$route.params)) {
          this.getNumber(val.id)
        }
      }
    },
    tipo_regalo_id(val) {
      const tipoRegaloId = {
        key: 'tipo_regalo_id',
        info: val,
      }
      this.$emit('input-tipo-regalo-id', tipoRegaloId)
    },
    user_id_vendedor(val) {
      const userIdVendedor = {
        key: 'user_id_vendedor',
        info: val,
      }
      this.$emit('input-user-id-vendedor', userIdVendedor)
    },
    fechasPago(val) {
      const fechaPago = {
        key: 'fecha_pago',
        info: val,
      }
      this.$emit('input-fecha-pago', fechaPago)
    },
    setEditInfo(value) {
      this.setContractData(value)
    },
  },
  async mounted() {
    this.getPais()
    this.getCuotasDepago()
    await this.getUserTypeAsesor()
    this.getTipoContrato()
  },
  methods: {
    formatNumber(value) {
      return new Intl.NumberFormat('de-DE', { currency: 'COP' }).format(value)
    },
    customFormatter(date) {
      return moment(date).add(5, 'hours').format('YYYY-MM-DD')
    },
    setContractData(value) {
      if (Object.keys(this.setEditInfo).length > 0 && this.setEditInfo.contrato != null) {
        this.n_contrato = value.contrato.n_contrato
        this.fecha_contrato = value.contrato.fecha_contrato
        this.tipo_contrato_id = value.contrato.tipo_contrato
        this.total_price = value.contrato.total_price
        this.observaciones = value.contrato.observaciones
        this.user_id_vendedor = value.asesor_academico
        this.setFechasThePago(value.contrato.fechas_pago)
      }
    },
    setFechasThePago(value) {
      if (value.length > 0) {
        for (let i = 0; i < value.length; i += 1) {
          this.fechasPago.push({
            id: i,
            title: i,
            date: moment(value[i].date_price).add(5, 'hours').format('YYYY/MM/DD').toString(),
            value: new Intl.NumberFormat('de-DE', { currency: 'COP' }).format(Math.floor(value[i].price)),
            price: Math.floor(value[i].price),
          })
        }
        this.totalPay =  this.fechasPago.reduce((acc, el) => {//eslint-disable-line
          return acc + parseFloat(el.price)
        }, 0)
        this.cuotas_pago = this.fechasPago.length
        this.orderByDateArray()
      }
    },
    setDate(val) {
      if (val) {
        return moment(new Date(val)).format('LL')
      }
      return ''
    },
    orderByDateArray() {
      this.fechasPago = this.fechasPago.slice().sort((a, b) => {//eslint-disable-line
        return new Date(a.date) - new Date(b.date)
      }) //eslint-disable-line
      let i = 0
      while (i < this.fechasPago.length) {
        this.fechasPago[i].id = i + 1
        i += 1
      }
    },
    validatePricing(valuePay, isEdit) {
      let total = this.totalPay + (this.numberCuotes.id * valuePay)
      if (isEdit) {
        total = valuePay
      }
      if (total > parseFloat(this.total_price)) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error',
            icon: 'XIcon',
            variant: 'danger',
            text: 'El valor de las cuotas no debe ser mayor al valor del contrato',
          },
        },
        {
          position: 'bottom-right',
        })
        return false
      }
      return true
    },
    saveDatePay() {
      if (this.isCreateEdit == null) {
        this.createDatePay()
      } else {
        this.UpdateDatePay(this.isCreateEdit)
      }
    },
    createDatePay() {
      this.$refs.FechaPagoForm.validate().then(success => {
        if (success && this.validatePricing(parseFloat(this.datePay.value), false)) {
          let count = 0
          while (count < this.numberCuotes.id) {
            this.fechasPago.push({
              id: count,
              title: count,
              date: moment(this.datePay.fecha_pago).add(count, 'month').add(5, 'hours').format('YYYY/MM/DD')
                .toString(),
              value: new Intl.NumberFormat('de-DE', { currency: 'COP' }).format(Math.floor(this.datePay.value)),
              price: Math.floor(this.datePay.value),
            })
            count += 1
          }
          this.datePay.fecha_pago = moment(this.fechasPago[this.fechasPago.length - 1].date).add(5, 'hours').add(1, 'month').format('YYYY-MM-DD')
          this.cuotas_pago = this.fechasPago.length
          this.totalPay =  this.fechasPago.reduce((acc, el) => {//eslint-disable-line
            return acc + parseFloat(el.price)
          }, 0)
          this.orderByDateArray()
          this.closeDayPay()
        }
      })
    },
    UpdateDatePay(val) {
      this.$refs.FechaPagoForm.validate().then(success => {
        if (success && this.validatePricing(parseFloat(this.datePay.value), true)) {
          for (const item of this.fechasPago) {//eslint-disable-line
            if (val === item.id) {
              item.date = moment(this.datePay.fecha_pago).add(5, 'hours').format('YYYY/MM/DD').toString()
              item.value = new Intl.NumberFormat('de-DE', { currency: 'COP' }).format(Math.floor(this.datePay.value))
              item.price = Math.floor(this.datePay.value)
            }
          }
          this.datePay.fecha_pago = moment(this.fechasPago[this.fechasPago.length - 1].date).add(5, 'hours').add(1, 'month').format('YYYY-MM-DD')
          this.cuotas_pago = this.fechasPago.length
          this.totalPay =  this.fechasPago.reduce((acc, el) => {//eslint-disable-line
            return acc + parseFloat(el.price)
          }, 0)
          this.orderByDateArray()
          this.closeDayPay()
        }
      })
    },
    deleteDayPay(val) {
      for (let i = 0; i < this.fechasPago.length; i += 1) {
        if (val.id === this.fechasPago[i].id) {
          this.fechasPago.splice(i, 1)
        }
      }
      this.datePay.fecha_pago = moment(this.fechasPago[this.fechasPago.length - 1].date).add(1, 'month').format('YYYY-MM-DD')
      this.cuotas_pago = this.fechasPago.length
      this.totalPay =  this.fechasPago.reduce((acc, el) => {//eslint-disable-line
        return acc + parseFloat(el.price)
      }, 0)
      this.orderByDateArray()
    },
    closeDayPay() {
      this.modalShow = false
    },
    agregarDayPay() {
      this.isCreateEdit = null
      this.modalShow = true
    },
    editDayPay(val) {
      this.isCreateEdit = val.id
      this.modalShow = true
      this.datePay.fecha_pago = moment(val.date).format('YYYY-MM-DD')
      this.datePay.value = val.price
    },
    clearDate() {
      const aux = this.fechasPago//eslint-disable-line
      this.fechasPago.push()
      this.fechasPago = aux
    },
    getTipoContrato() {
      this.isLoading = true
      this.$http.get('/v1/type-contract/get/type')
        .then(res => {
          this.isLoading = false
          this.rowsTipoContrato = res.data
        })
    },
    async getUserTypeAsesor() {
      this.isLoading = true
      this.$http.get('/v1/users/get/cargo')
        .then(res => {
          this.isLoading = false
          if (res.data.length > 0) {
            const result = res.data
            for (const value of result) { //eslint-disable-line
              this.rowsUserByType.push(
                {
                  id: value.id,
                  name: value.profile.fullname,
                },
              )
            }
          }
        })
    },
    getPais() {
      this.isLoadingPais = true
      this.$http.get('/v1/config-pais/get/status?countries=45, 97, 187, 192')
        .then(res => {
          this.isLoadingPais = false
          this.rowsPais = res.data
        })
    },
    getCuotasDepago() {
      for (let i = 1; i <= 36; i += 1) {
        this.rowsCuotasDePago.push(
          {
            id: i,
            name: i,
          },
        )
      }
    },
    getNumber(typeContractId) {
      this.isLoading = true
      this.$http.get(`/v1/contrato/get/contract/number/${typeContractId}`)
        .then(res => {
          this.isLoading = false
          this.n_contrato = res.data
        })
    },
  },
}
</script>
<style>
.events-wrapper {
  background-color: #2d3c78 !important;
}
.is-event {
  /* background-color: #2d3c78 !important; */
  background-color: #2d3c78 !important;
  border-color: #2d3c78 !important;
}
.is-today {
  background-color: #2d3c78 !important;
}
.event .date-num {
  color: white !important;
}
</style>
