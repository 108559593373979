<template>
  <b-row>
    <b-col
      cols="12"
    >
      <b-overlay
        :show="show"
        rounded="sm"
      >
        <validation-observer
          ref="UsersForm"
        >
          <b-tabs
            v-model="tabIndex"
          >
            <b-card>
              <b-tab active>
                <template #title>
                  <feather-icon icon="UserIcon" />
                  <span>Información del perfil</span>
                </template>
                <profile-data
                  :profile-data.sync="userData"
                  :is-active-avatar="isActiveAvatar"
                  @input-email="setTipoCargo"
                  @input-tipo-cargo-id="setTipoCargo"
                  @input-roles="setTipoCargo"
                  @input-password="setTipoCargo"
                  @input-avatar="setTipoCargo"
                  @input-first-name="setTipoCargo"
                  @input-last-name="setTipoCargo"
                  @input-status-id="setTipoCargo"
                />
              </b-tab>
              <b-tab
                :disabled="!is_active_estudiante"
              >
                <template #title>
                  <feather-icon icon="FlagIcon" />
                  <span>Información de viaje</span>
                </template>
                <traveler-data
                  :is-active-traveler="is_active_estudiante"
                  :set-edit-info="userData"
                  @input-tipo-documento-id="setTipoCargo"
                  @input-tarjeta-identidad="setTipoCargo"
                  @input-expedicion="setTipoCargo"
                  @input-pasaporte="setTipoCargo"
                  @input-pasp-expedicion="setTipoCargo"
                  @input-pasp-vencimiento="setTipoCargo"
                  @input-direccion="setTipoCargo"
                  @input-date-of-birth="setTipoCargo"
                  @input-lugar-nacimiento="setTipoCargo"
                  @input-pais-id="setTipoCargo"
                  @input-ciudad-id="setTipoCargo"
                  @input-municipio-id="setTipoCargo"
                  @input-tipo-stado-civil-id="setTipoCargo"
                  @input-fecha-viaje-id="setTipoCargo"
                  @input-tipo-gender="setTipoCargo"
                  @input-tipo-grado-academico-id="setTipoCargo"
                  @input-institucion-academica="setTipoCargo"
                  @input-fecha-terminacion="setTipoCargo"
                  @input-carrera-estudio="setTipoCargo"
                  @input-carrera-estudio-segunda-opcion="setTipoCargo"
                  @input-visa-americana="setTipoCargo"
                  @input-tipo-financiacion-id="setTipoCargo"
                  @input-tipo-estudio-id="setTipoCargo"
                  @input-preparatoria="setTipoCargo"
                  @input-celular="setTipoCargo"
                  @input-telefono="setTipoCargo"
                  @input-universidad-id="setTipoCargo"
                  @input-universidad-segunda-id="setTipoCargo"
                  @input-pais-id-universidad="setTipoCargo"
                  @input-pais-id-universidad-segunda="setTipoCargo"
                  @input-ciudad-universidad-id="setTipoCargo"
                  @input-ciudad-universidad-segunda-id="setTipoCargo"
                  @input-date-of-irlanda="setTipoCargo"
                  @input-semanas="setTipoCargo"
                />
              </b-tab>
              <b-tab
                :disabled="!is_active_estudiante"
              >
                <template #title>
                  <feather-icon icon="DollarSignIcon" />
                  <span>Información del contrato</span>
                </template>
                <contract-data
                  :is-active-contract="is_active_estudiante"
                  :set-edit-info="userData"
                  @input-n-contrato="setTipoCargo"
                  @input-fecha-contrato="setTipoCargo"
                  @input-total-price="setTipoCargo"
                  @input-cuotas-pago="setTipoCargo"
                  @input-observaciones="setTipoCargo"
                  @input-contrato-pais-id="setTipoCargo"
                  @input-contrato-ciudad-id="setTipoCargo"
                  @input-contrato-municipio-id="setTipoCargo"
                  @input-tipo-contrato-id="setTipoCargo"
                  @input-tipo-regalo-id="setTipoCargo"
                  @input-user-id-vendedor="setTipoCargo"
                  @input-fecha-pago="setTipoCargo"
                  @input-signature-date="setTipoCargo"
                />
              </b-tab>
              <b-card-footer class="text-center">
                <buttons-top
                  :is-active-button-back="is_active_estudiante"
                  :is-active-button-next="is_active_estudiante"
                  @back-button="backButton"
                  @next-button="nextButton"
                  @save-button="save"
                />
              </b-card-footer>
            </b-card>
          </b-tabs>
        </validation-observer>
      </b-overlay>
    </b-col>
  </b-row>
</template>
<script>
import store from '@/store'
import { onUnmounted } from '@vue/composition-api'
import { ValidationObserver } from 'vee-validate'
import {
  required, integer, alpha, alphaSpaces, email, ext,
} from '@validations'
import {
  BRow, BCol, BCard, BTabs, BTab, BOverlay, BCardFooter,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ButtonsTop from '@/views/users/ButtonsTop.vue'
import ProfileData from '@/views/users/profile/ProfileRegister.vue'
import TravelerData from '@/views/users/traveler/TravelerRegister.vue'
import ContractData from '@/views/users/contract/ContractRegister.vue'
import userStoreModule from '@/views/users/user/userStoreModule'

export default {
  components: {
    BRow,
    BCol,
    ValidationObserver,
    BCard,
    BTabs,
    BTab,
    ButtonsTop,
    BOverlay,
    BCardFooter,
    ProfileData,
    TravelerData,
    ContractData,
  },
  directives: {
    Ripple,
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'app-user'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })
  },
  data() {
    return {
      show: false,
      tabIndex: 0,
      id: null,
      // Users
      emailUsers: null,
      tipo_cargo_id: [],
      roles: [],
      password: null,
      // profile
      avatar: null,
      first_name: null,
      last_name: null,

      // viaje
      carrera_estudio: null,
      carrera_estudio_segunda_opcion: null,
      institucion_academica: null,
      fecha_terminacion: null,
      preparatoria: null,
      lugar_nacimiento: null,
      tarjeta_identidad: null,
      expedicion: null,
      pasaporte: null,
      celular: null,
      telefono: null,
      direccion: null,
      date_of_birth: null,
      pasp_expedicion: null,
      pasp_vencimiento: null,
      visa_americana: null,
      tipo_gender: null,
      tipo_documento_id: null,
      municipio_id: null,
      ciudad_id: [],
      pais_id: null,
      tipo_financiacion_id: null,
      tipo_estudio_id: null,
      pais_id_universidad: null,
      pais_id_universidad_segunda: null,
      ciudad_universidad_id: null,
      ciudad_universidad_segunda_id: null,
      universidad_id: null,
      universidad_segunda_id: null,
      fecha_viaje_id: null,
      tipo_stado_civil_id: null,
      tipo_grado_academico_id: null,
      semanas: null,
      date_of_irlanda: null,
      itemDayPay: [],

      // Contrato
      n_contrato: null,
      signature_date: false,
      fecha_contrato: null,
      total_price: null,
      cuotas_pago: null,
      observaciones: null,
      contrato_pais_id: null,
      tipo_contrato_id: null,
      tipo_regalo_id: [],
      user_id_vendedor: [],
      fecha_pago: [],

      status_id: [],
      value: [],
      options: [],
      formatted: '',
      userData: {},

      // result Data
      resultUser: {},
      resultContract: {},
      resultFechaPagos: {},
      // validation rules
      required,
      integer,
      alpha,
      alphaSpaces,
      email,
      ext,
    }
  },
  computed: {
    isActiveAvatar() {
      return 'id' in this.$route.params
    },
    is_active_estudiante() {
      if ('id' in this.tipo_cargo_id) {
        if (this.tipo_cargo_id.id === 1) {
          return true
        }
      }
      return false
    },
  },
  mounted() {
    this.setDataUser()
  },
  methods: {
    savePicture(id) {
      const data = new FormData()
      data.append('avatar', this.avatar)
      this.$http.put(`/v1/users/avatar/${id}`, data).then(res => {//eslint-disable-line
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Foto',
            icon: 'CheckCircleIcon',
            variant: 'success',
            text: 'Se ha actualizado correctamente.',
          },
        },
        {
          position: 'top-center',
        })
        this.$emit('edit-result')
      }).catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error',
            icon: 'XIcon',
            variant: 'danger',
            text: error.response.data.message,
          },
        },
        {
          position: 'bottom-right',
        })
      })
    },
    setTipoCargo(value) {
      this.[value.key] = value.info
      if (value.key === 'avatar') {
        this.savePicture(this.$route.params.id)
      }
    },
    backButton() {
      this.tabIndex -= 1
    },
    nextButton() {
      this.tabIndex += 1
    },
    close() {
      this.$router.go(-1)
    },
    setDataUser() {
      if ('id' in this.$route.params) {
        this.show = true
        this.$store.dispatch('app-user/fetchUser', { id: this.$route.params.id })
          .then(response => {
            this.show = false
            this.userData = response.data.userData
          })
          .catch(error => {
            if (error.response.status === 404) {
              this.userData = undefined
            }
            this.show = false
          })
      }
    },
    save() {
      this.$refs.UsersForm.validate().then(success => {
        if (success) {
          const rolId = []
          if (this.roles.length > 0) {
            rolId.push(this.roles[0].id)
          } else {
            rolId.push(this.roles.id)
          }
          const dataUser = {
            email: this.emailUsers,
            roles: rolId,
            first_name: this.first_name,
            last_name: this.last_name,
            password: this.password,
            status_id: this.status_id.id,
            carrera_estudio: this.carrera_estudio,
            carrera_estudio_segunda_opcion: this.carrera_estudio_segunda_opcion,
            institucion_academica: this.institucion_academica,
            fecha_terminacion: this.fecha_terminacion,
            preparatoria: this.preparatoria ? this.preparatoria.id : this.preparatoria,
            lugar_nacimiento: this.lugar_nacimiento,
            tarjeta_identidad: this.tarjeta_identidad,
            expedicion: this.expedicion,
            pasaporte: this.pasaporte,
            celular: this.celular,
            telefono: this.telefono,
            direccion: this.direccion,
            date_of_birth: this.date_of_birth,
            pasp_expedicion: this.pasp_expedicion,
            pasp_vencimiento: this.pasp_vencimiento,
            visa_americana: this.visa_americana ? this.visa_americana.id : this.visa_americana,
            tipo_gender: this.tipo_gender ? this.tipo_gender.id : this.tipo_gender,
            tipo_documento_id: this.tipo_documento_id ? this.tipo_documento_id.id : this.tipo_documento_id,
            municipio_id: this.municipio_id ? this.municipio_id.id : this.municipio_id,
            ciudad_id: this.ciudad_id ? this.ciudad_id.id : this.ciudad_id,
            pais_id: this.pais_id ? this.pais_id.id : this.pais_id,
            tipo_financiacion_id: this.tipo_financiacion_id ? this.tipo_financiacion_id.id : this.tipo_financiacion_id,
            tipo_estudio_id: this.tipo_estudio_id ? this.tipo_estudio_id.id : this.tipo_estudio_id,
            pais_id_universidad: this.pais_id_universidad ? this.pais_id_universidad.id : this.pais_id_universidad,
            pais_id_universidad_segunda: this.pais_id_universidad_segunda ? this.pais_id_universidad_segunda.id : this.pais_id_universidad_segunda,
            ciudad_universidad_id: this.ciudad_universidad_id ? this.ciudad_universidad_id.id : this.ciudad_universidad_id,
            ciudad_universidad_segunda_id: this.ciudad_universidad_segunda_id ? this.ciudad_universidad_segunda_id.id : this.ciudad_universidad_segunda_id,
            universidad_id: this.universidad_id ? this.universidad_id.id : this.universidad_id,
            universidad_segunda_id: this.universidad_segunda_id ? this.universidad_segunda_id.id : this.universidad_segunda_id,
            fecha_viaje_id: this.fecha_viaje_id ? this.fecha_viaje_id.id : this.fecha_viaje_id,
            tipo_stado_civil_id: this.tipo_stado_civil_id ? this.tipo_stado_civil_id.id : this.tipo_stado_civil_id,
            tipo_grado_academico_id: this.tipo_grado_academico_id ? this.tipo_grado_academico_id.id : this.tipo_grado_academico_id,
            date_of_irlanda: this.date_of_irlanda,
            semanas: this.semanas,
          }
          let userIdVendedor = null
          if (this.user_id_vendedor.length > 0) {
            // eslint-disable-next-line prefer-destructuring
            userIdVendedor = this.user_id_vendedor[0].id
          } else {
            userIdVendedor = this.user_id_vendedor.id
          }
          const dataContract = {
            n_contrato: this.n_contrato,
            signature_date: this.signature_date,
            fecha_contrato: this.fecha_contrato,
            total_price: this.total_price,
            cuotas_pago: this.cuotas_pago,
            observaciones: this.observaciones,
            pais_id: this.contrato_pais_id ? this.contrato_pais_id.id : this.contrato_pais_id,
            tipo_contrato_id: this.tipo_contrato_id ? this.tipo_contrato_id.id : this.tipo_contrato_id,
            tipo_regalo_id: this.tipo_regalo_id,
            user_id_vendedor: userIdVendedor,
          }
          const dataFechasPago = {
            fecha_pago: this.fecha_pago,
          }
          if ('id' in this.$route.params) {
            this.updateUsers(dataUser, dataContract, dataFechasPago)
          } else {
            this.create(dataUser, dataContract, dataFechasPago)
          }
        } else {
          const value = this.is_active_estudiante ? 'Por favor completa los datos de usuario, viaje y contrato' : 'Por favor completa los datos del usuario'
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Completar datos',
              icon: 'XIcon',
              variant: 'danger',
              text: value,
            },
          },
          {
            position: 'top-center',
          })
        }
      })
    },
    createUser(dataUser) {
      return new Promise((resolve, reject) => {
        this.$http.post(`/v1/users/register/${this.tipo_cargo_id.id}`, dataUser).then(res => {//eslint-disable-line
          resolve(res)
        }).catch(error => {
          reject(error.response)
        })
      })
    },
    createContract(id, dataContract) {
      return new Promise((resolve, reject) => {
        this.$http.post(`/v1/contrato/create/${id}`, dataContract).then(res => {//eslint-disable-line
          resolve(res)
        }).catch(error => {
          reject(error.response)
        })
      })
    },
    createFechaPago(id, dataFechasPago) {
      return new Promise((resolve, reject) => {
        this.$http.post(`/v1/fechas-pago/create/${id}`, dataFechasPago).then(res => {//eslint-disable-line
          resolve(res)
        }).catch(error => {
          reject(error.response)
        })
      })
    },
    createReportVentas(idUserBuy, contratoIdBuy) {
      let userIdVendedor = null
      if (this.user_id_vendedor.length > 0) {
        // eslint-disable-next-line prefer-destructuring
        userIdVendedor = this.user_id_vendedor[0].id
      } else {
        userIdVendedor = this.user_id_vendedor.id
      }
      return new Promise((resolve, reject) => {
        const reportVentasBuy = {
          id_user_vendedor: userIdVendedor,
          id_user_comprador: idUserBuy,
          contrato_id: contratoIdBuy,
        }
        this.$http.post(`/v1/reporte-ventas/create/`, reportVentasBuy).then(res => {//eslint-disable-line
          resolve(res)
        }).catch(error => {
          reject(error.response)
        })
      })
    },
    async create(dataUser, dataContract, dataFechasPago) {
      try {
        if (this.resultUser.status !== 200) {
          this.resultUser = await this.createUser(dataUser)
        }
        if (this.tipo_cargo_id.id === 1) {
          if (this.resultContract.status !== 200) {
            this.resultContract = await this.createContract(this.resultUser.data.data, dataContract)
            await this.createReportVentas(this.resultUser.data.data, this.resultContract.data.result)
          }
          if (this.resultFechaPagos.status !== 200) {
            this.resultFechaPagos = await this.createFechaPago(this.resultContract.data.result, dataFechasPago)
          }
        }
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Se ha creado satisfactoriamente',
            icon: 'CheckCircleIcon',
            variant: 'success',
            text: 'El registro se ha guardado.',
          },
        },
        {
          position: 'top-center',
        })
        this.$router.push({
          name: 'users-list',
        })
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error',
            icon: 'XIcon',
            variant: 'danger',
            text: error.data[0].message,
          },
        },
        {
          position: 'bottom-right',
        })
      }
    },
    updateUser(dataUser) {
      return new Promise((resolve, reject) => {
        this.$http.put(`/v1/users/${this.tipo_cargo_id.id}`, dataUser).then(res => {//eslint-disable-line
          resolve(res)
        }).catch(error => {
          reject(error.response)
        })
      })
    },
    updateContract(id, dataContract) {
      // eslint-disable-next-line no-param-reassign
      dataContract.id_user = id
      return new Promise((resolve, reject) => {
        this.$http.put(`/v1/contrato/`, dataContract).then(res => {//eslint-disable-line
          resolve(res)
        }).catch(error => {
          reject(error.response)
        })
      })
    },
    updateFechaPago(id, dataFechasPago) {
      return new Promise((resolve, reject) => {
        this.$http.put(`/v1/fechas-pago/${id}`, dataFechasPago).then(res => {//eslint-disable-line
          resolve(res)
        }).catch(error => {
          reject(error.response)
        })
      })
    },
    async updateUsers(dataUser, dataContract, dataFechasPago) {
      try {
        if (this.resultUser.status !== 200) {
          // eslint-disable-next-line no-param-reassign
          dataUser.user_id = this.$route.params.id
          this.resultUser = await this.updateUser(dataUser)
        }
        if (this.tipo_cargo_id.id === 1) {
          if (this.resultContract.status !== 200) {
            this.resultContract = await this.updateContract(dataUser.user_id, dataContract)
            await this.createReportVentas(this.resultUser.data.data.user_id, this.resultContract.data.result)
          }
          if (this.resultFechaPagos.status !== 200) {
            this.resultFechaPagos = await this.updateFechaPago(this.resultContract.data.result, dataFechasPago)
          }
        }
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Se ha creado satisfactoriamente',
            icon: 'CheckCircleIcon',
            variant: 'success',
            text: 'El registro se ha guardado.',
          },
        },
        {
          position: 'top-center',
        })
        this.$router.go(-1)
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error',
            icon: 'XIcon',
            variant: 'danger',
            text: error.data[0].message,
          },
        },
        {
          position: 'bottom-right',
        })
      }
    },
  },
}
</script>

<style scoped>
#users
.card-header {
  border-bottom: 1px solid #dae1e7;
  background-color: transparent;
  padding-bottom: 1em;
  margin-bottom: 2em;
}
</style>
