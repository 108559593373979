var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',[_c('b-row',{staticClass:"justify-content-md-center"},[_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('b-row',[(_vm.isActiveAvatar)?_c('b-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"text-center",on:{"click":_vm.ActivateFormFile}},[_c('b-avatar',{attrs:{"src":_vm.getFilePathTraducido,"text":"Foto","size":"104px"}}),_c('br'),_vm._v(" Presiona para añadir una foto "),_c('hr')],1),_c('b-form-group',{attrs:{"label":"Foto de perfil","label-for":"users-avatar"}},[_c('validation-provider',{attrs:{"rules":"ext:jpg,jpeg,png","name":"Avatar"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-file',{ref:"file-input",staticClass:"mb-2",staticStyle:{"visibility":"hidden","position":"absolute","top":"0","left":"-5000px"},attrs:{"id":"fileInput","placeholder":"Selecciona un archivo","accept":"image/jpeg, image/png, image/jpeg"},model:{value:(_vm.avatar),callback:function ($$v) {_vm.avatar=$$v},expression:"avatar"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3351136321)})],1)],1):_vm._e(),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":"Nombre","label-for":"users-name"}},[_c('validation-provider',{attrs:{"name":"Nombre","vid":"nombre","rules":"required|alphaSpaces","immediate":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"users-name","placeholder":"Nombre","state":errors.length > 0 ? false : null,"name":"users-name"},model:{value:(_vm.first_name),callback:function ($$v) {_vm.first_name=$$v},expression:"first_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":"Apellidos","label-for":"users-apellido"}},[_c('validation-provider',{attrs:{"name":"Apellidos","vid":"apellidos","rules":"required|alphaSpaces","immediate":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"users-apellido","placeholder":"Apellidos","state":errors.length > 0 ? false : null,"name":"users-apellido"},model:{value:(_vm.last_name),callback:function ($$v) {_vm.last_name=$$v},expression:"last_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","sm":"6"}},[_c('b-form-group',{attrs:{"label":"Email","label-for":"users-email"}},[_c('validation-provider',{attrs:{"name":"Email","vid":"email","rules":"required|email","immediate":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"users-email","placeholder":"Email","state":errors.length > 0 ? false : null,"name":"users-email"},model:{value:(_vm.emailUsers),callback:function ($$v) {_vm.emailUsers=$$v},expression:"emailUsers"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),('id' in this.$route.params ? false : true)?_c('b-col',{attrs:{"cols":"12","sm":"6"}},[_c('b-form-group',[_c('div',{staticClass:"d-flex justify-content-between"},[_c('label',{attrs:{"for":"login-password"}},[_vm._v("Contraseña")])]),_c('validation-provider',{attrs:{"name":"Contraseña","vid":"password","rules":"required","immediate":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"login-password","state":errors.length > 0 ? false : null,"type":_vm.passwordFieldType,"name":"login-password","placeholder":"Contraseña","autocomplete":"new-password"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2771787781)})],1)],1):_vm._e(),_c('b-col',{attrs:{"cols":"12","sm":"6"}},[_c('b-form-group',{attrs:{"label":"Rol","label-for":"users-roles"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Rol","immediate":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"name":"users-roles","label":"name","loading":_vm.isLoadingRoles,"options":_vm.rowsRoles,"clearable":false,"placeholder":"Seleccione una opción"},model:{value:(_vm.roles),callback:function ($$v) {_vm.roles=$$v},expression:"roles"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","sm":"6"}},[_c('b-form-group',{attrs:{"label":"Tipo de cargo","label-for":"users-tipo_cargo_id"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Tipo de cargo","immediate":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"name":"users-tipo_cargo_id","label":"name","loading":_vm.isLoadingCargo,"options":_vm.rowsTipoCargo,"clearable":false,"placeholder":"Seleccione una opción"},model:{value:(_vm.tipo_cargo_id),callback:function ($$v) {_vm.tipo_cargo_id=$$v},expression:"tipo_cargo_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","sm":"6"}},[_c('b-form-group',{attrs:{"label":"Estado","label-for":"users-status_id"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Estado","immediate":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"name":"users-status_id","label":"name","loading":_vm.isLoadingStatus,"options":_vm.rowsStatus,"clearable":false,"placeholder":"Seleccione una opción"},model:{value:(_vm.status_id),callback:function ($$v) {_vm.status_id=$$v},expression:"status_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }