var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isActiveTraveler)?_c('b-form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"Tipo de documento","label-for":"users-tipo_documento_id"}},[_c('validation-provider',{attrs:{"rules":"required","immediate":"","name":"Tipo de cargo"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"name":"users-tipo_documento_id","label":"name","options":_vm.rowsTipoDocumento,"placeholder":"Seleccione una opción"},model:{value:(_vm.tipo_documento_id),callback:function ($$v) {_vm.tipo_documento_id=$$v},expression:"tipo_documento_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,4213911895)})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"Documento de identidad","label-for":"users-tarjeta_identidad"}},[_c('validation-provider',{attrs:{"name":"documento de identidad","vid":"documento de identidad","rules":"required|integer","immediate":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"users-tarjeta_identidad","placeholder":"Documento de identidad","state":errors.length > 0 ? false:null,"name":"users-tarjeta_identidad"},model:{value:(_vm.tarjeta_identidad),callback:function ($$v) {_vm.tarjeta_identidad=$$v},expression:"tarjeta_identidad"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1688851502)})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"Ciudad de expedición","label-for":"users-expedicion"}},[_c('validation-provider',{attrs:{"name":"expedicion","vid":"expedicion","rules":"required|alphaSpaces","immediate":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"users-expedicion","placeholder":"expedicion","state":errors.length > 0 ? false:null,"name":"users-expedicion"},model:{value:(_vm.expedicion),callback:function ($$v) {_vm.expedicion=$$v},expression:"expedicion"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1837529325)})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"Pasaporte","label-for":"users-pasaporte"}},[_c('validation-provider',{attrs:{"name":"pasaporte","vid":"pasaporte","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"users-pasaporte","placeholder":"Pasaporte","state":errors.length > 0 ? false:null,"name":"users-pasaporte"},model:{value:(_vm.pasaporte),callback:function ($$v) {_vm.pasaporte=$$v},expression:"pasaporte"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,554156685)})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"Pasaporte Fecha de expedición","vid":"Pasaporte Fecha de expedición","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"example-input"}},[_vm._v(" Pasaporte Fecha de expedición ")]),_c('datepicker',{attrs:{"placeholder":"Fecha de expedición","use-utc":true,"bootstrap-styling":true,"format":"d MMMM yyyy","state":errors.length > 0 ? false : null,"language":_vm.espanish},model:{value:(_vm.pasp_expedicion),callback:function ($$v) {_vm.pasp_expedicion=$$v},expression:"pasp_expedicion"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2960345909)})],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"Pasaporte Fecha de vencimiento","vid":"Pasaporte Fecha de vencimiento","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"example-input"}},[_vm._v("Pasaporte Fecha de vencimiento")]),_c('datepicker',{attrs:{"placeholder":"Fecha de vencimiento","use-utc":true,"bootstrap-styling":true,"format":"d MMMM yyyy","state":errors.length > 0 ? false : null,"language":_vm.espanish},model:{value:(_vm.pasp_vencimiento),callback:function ($$v) {_vm.pasp_vencimiento=$$v},expression:"pasp_vencimiento"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1208332952)})],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"Dirección","label-for":"users-direccion"}},[_c('validation-provider',{attrs:{"name":"direccion","vid":"direccion","rules":"required","immediate":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"users-direccion","placeholder":"Dirección","state":errors.length > 0 ? false:null,"name":"users-direccion"},model:{value:(_vm.direccion),callback:function ($$v) {_vm.direccion=$$v},expression:"direccion"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,436722737)})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"Fecha de nacimiento","label-for":"users-date_of_birth"}},[_c('validation-provider',{attrs:{"name":"Fecha de nacimiento","vid":"Fecha de nacimiento","rules":"required","immediate":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('datepicker',{attrs:{"placeholder":"Fecha de nacimiento","input-class":"form-control","bootstrap-styling":true,"use-utc":true,"format":"d MMMM yyyy","language":_vm.espanish},model:{value:(_vm.date_of_birth),callback:function ($$v) {_vm.date_of_birth=$$v},expression:"date_of_birth"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3384740019)})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"Lugar Nacimiento","label-for":"users-lugar_nacimiento"}},[_c('validation-provider',{attrs:{"name":"Lugar Nacimiento","vid":"Lugar Nacimiento","rules":"required|alphaSpaces","immediate":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"users-lugar_nacimiento","placeholder":"Lugar Nacimiento","state":errors.length > 0 ? false:null,"name":"users-lugar_nacimiento"},model:{value:(_vm.lugar_nacimiento),callback:function ($$v) {_vm.lugar_nacimiento=$$v},expression:"lugar_nacimiento"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,4009080050)})],1)],1),_c('b-col',{attrs:{"cols":"12","sm":"4"}},[_c('b-form-group',{attrs:{"label":"País","label-for":"users-pais_id"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Pais","immediate":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"name":"users-pais_id","label":"name","options":_vm.rowsPais,"placeholder":"Seleccione una opción"},model:{value:(_vm.pais_id),callback:function ($$v) {_vm.pais_id=$$v},expression:"pais_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2504771614)})],1)],1),_c('b-col',{attrs:{"cols":"12","sm":"4"}},[_c('b-form-group',{attrs:{"label":"Departamento","label-for":"users-ciudad_id"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Departamento","immediate":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"name":"users-ciudad_id","label":"name","options":_vm.rowsDepartamento,"placeholder":"Seleccione una opción"},model:{value:(_vm.ciudad_id),callback:function ($$v) {_vm.ciudad_id=$$v},expression:"ciudad_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,997825567)})],1)],1),_c('b-col',{attrs:{"cols":"12","sm":"4"}},[_c('b-form-group',{attrs:{"label":"Ciudad","label-for":"users-municipio_id"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Ciudad","immediate":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"name":"users-municipio_id","label":"name","options":_vm.rowsCiudad,"placeholder":"Seleccione una opción"},model:{value:(_vm.municipio_id),callback:function ($$v) {_vm.municipio_id=$$v},expression:"municipio_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2421149227)})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"Estado civil","label-for":"users-tipo_stado_civil_id"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Estado civil","immediate":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"name":"users-tipo_stado_civil_id","label":"name","options":_vm.rowsEstadoCivil,"placeholder":"Seleccione una opción"},model:{value:(_vm.tipo_stado_civil_id),callback:function ($$v) {_vm.tipo_stado_civil_id=$$v},expression:"tipo_stado_civil_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3916172580)})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"Fecha de viaje","label-for":"users-fecha_viaje_id"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Fecha de viaje","immediate":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"name":"users-fecha_viaje_id","label":"name","options":_vm.rowsFechaViaje,"placeholder":"Seleccione una opción"},model:{value:(_vm.fecha_viaje_id),callback:function ($$v) {_vm.fecha_viaje_id=$$v},expression:"fecha_viaje_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2176523277)})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"Genero","label-for":"users-tipo_gender"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Genero","immediate":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"name":"users-tipo_gender","label":"name","options":_vm.rowsGenero,"placeholder":"Seleccione una opción"},model:{value:(_vm.tipo_gender),callback:function ($$v) {_vm.tipo_gender=$$v},expression:"tipo_gender"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1754188257)})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"Grado Academico","label-for":"users-tipo_grado_academico_id"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Grado Academico","immediate":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"name":"users-tipo_grado_academico_id","label":"name","options":_vm.rowsGradoAcademico,"placeholder":"Seleccione una opción"},model:{value:(_vm.tipo_grado_academico_id),callback:function ($$v) {_vm.tipo_grado_academico_id=$$v},expression:"tipo_grado_academico_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,449370176)})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"Institución académica","label-for":"users-institucion_academica"}},[_c('validation-provider',{attrs:{"name":"Institución académica","vid":"Institución académica","rules":"required|alphaSpaces","immediate":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"users-institucion_academica","placeholder":"Institución académica","state":errors.length > 0 ? false:null,"name":"users-institucion_academica"},model:{value:(_vm.institucion_academica),callback:function ($$v) {_vm.institucion_academica=$$v},expression:"institucion_academica"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1156663298)})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"Fecha de terminación de estudios","label-for":"users-fecha_terminacion"}},[_c('validation-provider',{attrs:{"name":"Fecha de terminación de estudios","vid":"Fecha de terminación de estudios","rules":"required|integer","immediate":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"users-fecha_terminacion","placeholder":"Fecha de terminación de estudios","state":errors.length > 0 ? false:null,"name":"users-fecha_terminacion"},model:{value:(_vm.fecha_terminacion),callback:function ($$v) {_vm.fecha_terminacion=$$v},expression:"fecha_terminacion"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3246712872)})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":"Carrera o tema de estudio","label-for":"users-carrera_estudio"}},[_c('validation-provider',{attrs:{"name":"Carrera o tema de estudio","vid":"carrera_estudio","rules":"required|alphaSpaces","immediate":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"users-carrera_estudio","placeholder":"Carrera o tema de estudio","state":errors.length > 0 ? false:null,"name":"users-carrera_estudio"},model:{value:(_vm.carrera_estudio),callback:function ($$v) {_vm.carrera_estudio=$$v},expression:"carrera_estudio"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2062379489)})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":"Segunda Carrera de estudio","label-for":"users-carrera_estudio_segunda_opcion"}},[_c('validation-provider',{attrs:{"name":"Segunda Carrera de estudio","vid":"Segunda Carrera de estudio","rules":"required|alphaSpaces","immediate":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"users-carrera_estudio_segunda_opcion","placeholder":"Segunda Carrera de estudio","state":errors.length > 0 ? false:null,"name":"users-carrera_estudio_segunda_opcion"},model:{value:(_vm.carrera_estudio_segunda_opcion),callback:function ($$v) {_vm.carrera_estudio_segunda_opcion=$$v},expression:"carrera_estudio_segunda_opcion"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2801524615)})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"Visa americana","label-for":"users-visa_americana"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Visa americana","immediate":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"name":"users-visa_americana","label":"name","options":_vm.rowsVisa,"placeholder":"Seleccione una opción"},model:{value:(_vm.visa_americana),callback:function ($$v) {_vm.visa_americana=$$v},expression:"visa_americana"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1516918296)})],1)],1),_c('b-col',{attrs:{"cols":"12","sm":"4"}},[_c('b-form-group',{attrs:{"label":"¿Quién Financia mis Estudios?","label-for":"users-tipo_financiacion_id"}},[_c('validation-provider',{attrs:{"rules":"required","name":"¿Quién Financia mis Estudios?","immediate":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"name":"users-tipo_financiacion_id","label":"name","options":_vm.rowsFinancyStudy,"placeholder":"Seleccione una opción"},model:{value:(_vm.tipo_financiacion_id),callback:function ($$v) {_vm.tipo_financiacion_id=$$v},expression:"tipo_financiacion_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3256139310)})],1)],1),_c('b-col',{attrs:{"cols":"12","sm":"4"}},[_c('b-form-group',{attrs:{"label":"Tipo de Estudio","label-for":"users-tipo_estudio_id"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Tipo de Estudio","immediate":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"name":"users-tipo_estudio_id","label":"name","options":_vm.rowsTipoStudio,"placeholder":"Seleccione una opción"},model:{value:(_vm.tipo_estudio_id),callback:function ($$v) {_vm.tipo_estudio_id=$$v},expression:"tipo_estudio_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2502151111)})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"Preparatoria","label-for":"users-Preparatoria"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Preparatoria","immediate":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"name":"users-Preparatoria","label":"name","options":_vm.rowsVisa,"placeholder":"Seleccione una opción"},model:{value:(_vm.preparatoria),callback:function ($$v) {_vm.preparatoria=$$v},expression:"preparatoria"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,4077244056)})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"Celular","label-for":"users-celular"}},[_c('validation-provider',{attrs:{"name":"Celular","vid":"celular","rules":"required|integer","immediate":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"users-celular","placeholder":"celular","state":errors.length > 0 ? false:null,"name":"users-celular"},model:{value:(_vm.celular),callback:function ($$v) {_vm.celular=$$v},expression:"celular"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3382145037)})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"Teléfono","label-for":"users-telefono"}},[_c('validation-provider',{attrs:{"name":"telefono","vid":"telefono","rules":"integer","immediate":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"users-telefono","placeholder":"telefono","state":errors.length > 0 ? false:null,"name":"users-telefono"},model:{value:(_vm.telefono),callback:function ($$v) {_vm.telefono=$$v},expression:"telefono"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2652255341)})],1)],1),_c('b-col',{attrs:{"cols":"12","sm":"4"}},[_c('b-form-group',{attrs:{"label":"País de viaje (*)","label-for":"users-pais_id"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Pais de viaje","immediate":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"name":"users-pais_id","label":"name","options":_vm.rowsPaisViaje,"placeholder":"Seleccione una opción"},model:{value:(_vm.pais_id_universidad),callback:function ($$v) {_vm.pais_id_universidad=$$v},expression:"pais_id_universidad"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,450754296)})],1)],1),_c('b-col',{attrs:{"cols":"12","sm":"4"}},[_c('b-form-group',{attrs:{"label":"Ciudad de viaje (*)","label-for":"users-ciudad_universidad_id"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Ciudad de viaje","immediate":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"name":"users-ciudad_universidad_id","label":"name","options":_vm.rowsCiudadRusia,"placeholder":"Seleccione una opción"},model:{value:(_vm.ciudad_universidad_id),callback:function ($$v) {_vm.ciudad_universidad_id=$$v},expression:"ciudad_universidad_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1020016727)})],1)],1),((_vm.pais_id_universidad && _vm.pais_id_universidad.id != 15))?_c('b-col',{attrs:{"cols":"12","sm":"4"}},[_c('b-form-group',{attrs:{"label":"Universidad (*)","label-for":"users-universidad_id"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Universidad","immediate":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"name":"users-universidad_id","label":"name","options":_vm.rowsUniversidad1,"placeholder":"Seleccione una opción"},model:{value:(_vm.universidad_id),callback:function ($$v) {_vm.universidad_id=$$v},expression:"universidad_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,498618444)})],1)],1):_vm._e(),((_vm.pais_id_universidad && _vm.pais_id_universidad.id == 97) || (_vm.pais_id_universidad && _vm.pais_id_universidad.id == 60) || (_vm.pais_id_universidad && _vm.pais_id_universidad.id == 15))?_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":"Fecha de viaje","label-for":"users-date_of_birth"}},[_c('validation-provider',{attrs:{"name":"Fecha de viaje","vid":"Fecha de viaje","rules":"required","immediate":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('datepicker',{attrs:{"placeholder":"Fecha de viaje","input-class":"form-control","bootstrap-styling":true,"use-utc":true,"format":"d MMMM yyyy","language":_vm.espanish},model:{value:(_vm.date_of_irlanda),callback:function ($$v) {_vm.date_of_irlanda=$$v},expression:"date_of_irlanda"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3341111467)})],1)],1):_vm._e(),((_vm.pais_id_universidad && _vm.pais_id_universidad.id == 97) || (_vm.pais_id_universidad && _vm.pais_id_universidad.id == 60) || (_vm.pais_id_universidad && _vm.pais_id_universidad.id == 15))?_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":"Semanas de estadia","label-for":"users-telefono"}},[_c('validation-provider',{attrs:{"name":"semanas","vid":"semanas","rules":"integer|required","immediate":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"users-semanas","placeholder":"Semanas de estadia","state":errors.length > 0 ? false:null,"name":"users-semanas"},model:{value:(_vm.semanas),callback:function ($$v) {_vm.semanas=$$v},expression:"semanas"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2494167043)})],1)],1):_vm._e(),(_vm.pais_id_universidad && _vm.pais_id_universidad.id == 149)?_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":"Semanas","label-for":"users-telefono"}},[_c('validation-provider',{attrs:{"name":"semanas","vid":"semanas","rules":"integer|required","immediate":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"users-semanas","placeholder":"Semanas","state":errors.length > 0 ? false:null,"name":"users-semanas"},model:{value:(_vm.semanas),callback:function ($$v) {_vm.semanas=$$v},expression:"semanas"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1295373101)})],1)],1):_vm._e()],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }